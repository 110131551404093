import React, { useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box, CssBaseline, Stack, Paper, IconButton } from '@mui/material';
import { Security, People, VerifiedUser, Favorite } from '@mui/icons-material';
import './App.css';

const App = () => {
  useEffect(() => {
    const videoElement = document.querySelector('.video');
    if (videoElement) {
      videoElement.load(); // Load the video manually after mounting
    }
  }, []);

  return (
    <div className="root">
      <CssBaseline />
      {/* Positioning AppBar absolutely to overlay on video */}
      <AppBar position="fixed" className="appBar">
        <Toolbar>
          <Typography variant="h6" className="logo">
            D&D Healthcare
          </Typography>
          <nav className="nav">
            <Button className="navButton" href="#tagline">Home</Button>
            <Button className="navButton" href="#vision">Our Vision</Button>
            <Button className="navButton" href="#story">Our Story</Button>
            <Button className="navButton" href="#why">Why Choose Us</Button>
            <Button className="navButton" href="#products">Products & Services</Button>
            <Button className="navButton" href="#model">Our Model</Button>
          </nav>
        </Toolbar>
      </AppBar>
      <main>
        <section id="tagline" className="tagline">
          {/* <video className="video" src={`${process.env.PUBLIC_URL}/video.mp4`} autoPlay loop muted preload="auto" crossOrigin="anonymous" /> */}
          <Typography variant="h2" component="h2" gutterBottom className="tagline-text">
            Inspire Health. Empower Wellness.
          </Typography>
        </section>
        <Container>
          <section id="vision" className="section vision-section">
            <Typography variant="h4" component="h2" gutterBottom>
              Our Vision
            </Typography>
            <Typography>
              We aim to transform healthcare experiences and empower individuals to thrive in every aspect of life. Our goal is to inspire healthier living through pioneering products and personalized services, making wellness accessible to all.
            </Typography>
          </section>
          <section id="mission" className="section mission-section">
            <Typography variant="h4" component="h2" gutterBottom>
              Our Mission
            </Typography>
            <Typography>
              At D&D Healthcare, we’re on a mission to shake up the healthcare world! We believe that with the right tech and a bit of creativity, we can craft amazing solutions that make life better for everyone. From individuals to entire communities, we’re here to inspire health and make wellness accessible, exciting, and effective for all. Join us on this journey to better living, where innovation meets inspiration!
            </Typography>
            <Box className="icon-group">
              <IconButton><Favorite /></IconButton>
              <IconButton><Security /></IconButton>
              <IconButton><People /></IconButton>
            </Box>
          </section>
          <section id="story" className="section story-section">
            <Typography variant="h4" component="h2" gutterBottom>
              Our Story
            </Typography>
            <Typography paragraph>
              What happens when a healthcare enthusiast, a tech geek, and wellness advocates walk into a café? We start D&D Healthcare!
            </Typography>
            <Typography paragraph>
              Every great idea begins with a story, and ours kicked off over coffee (and maybe a little too much caffeine). As a team of passionate healthcare enthusiasts, tech geeks, and wellness advocates, we found ourselves constantly frustrated with how complicated healthcare could be. We thought, "Why does something so essential have to be so difficult?"
            </Typography>
            <Typography paragraph>
              So we decided to take matters into our own hands and create D&D Healthcare. Our mission was simple: use technology and compassion to make healthcare smarter, simpler, and more personalized. We wanted to develop products that not only solve problems but also bring a bit of joy and ease into people's lives.
            </Typography>
          </section>
          <section id="why" className="section why-section">
            <Typography variant="h4" component="h2" gutterBottom>
              Why Choose Us?
            </Typography>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={4} className="why-items">
              <Paper elevation={3} className="why-item">
                <VerifiedUser className="icon" />
                <Typography><strong>Innovative Solutions:</strong> <br/><br/>  At D&D Healthcare, innovation sits at the heart of everything we do. We harness cutting-edge technology to develop forward-thinking healthcare solutions that simplify and enhance your health journey.</Typography>
              </Paper>
              <Paper elevation={3} className="why-item">
                <People className="icon" />
                <Typography><strong>Personalized Care:</strong><br/><br/>   We understand that healthcare is personal. That’s why our products and services are tailored to fit your unique needs, ensuring that you get the support and solutions that work best for you.</Typography>
              </Paper>
              <Paper elevation={3} className="why-item">
                <Favorite className="icon" />
                <Typography><strong>Compassion:</strong><br/><br/>   We truly care about our clients. Empathy and compassion drive us to create solutions that not only address your needs but also improve your quality of life. We're here to support you every step of the way.</Typography>
              </Paper>
            </Stack>
          </section>
          <section id="products" className="section products-section">
            <Typography variant="h4" component="h2" gutterBottom>
              Products & Services
            </Typography>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
              <Paper elevation={3} className="product">
                <Typography variant="h5"><b>CredChainMD</b></Typography>
                <Typography>Revolutionizing Credentialing with Blockchain Technology</Typography>
                <ul>
                  <li><Typography><strong>Secure Verification:</strong> Utilizing blockchain technology for tamper-proof credentialing.</Typography></li>
                  <li><Typography><strong>Efficient Process:</strong> Streamlined operations that save time and resources.</Typography></li>
                  <li><Typography><strong>Trusted System:</strong> Built on trust and transparency for better healthcare management.</Typography></li>
                </ul>
              </Paper>
              <Paper elevation={3} className="product">
                <Typography variant="h5"><b>SamVrudh Healthcare</b></Typography>
                <Typography>Compassionate Caregiving for Senior Citizens</Typography>
                <ul>
                  <li><Typography><strong>Personalized Care Plans:</strong> Tailored to meet the specific needs of each individual.</Typography></li>
                  <li><Typography><strong>Professional Caregivers:</strong> Trained and compassionate staff committed to high-quality care.</Typography></li>
                  <li><Typography><strong>Peace of Mind:</strong> Ensuring the well-being of your loved ones with reliable services.</Typography></li>
                </ul>
              </Paper>
              <Paper elevation={3} className="product">
                <Typography variant="h5"><b>Riseve</b></Typography>
                <Typography>Empowering Postpartum Women</Typography>
                <ul>
                  <li><Typography><strong>Career Support:</strong> Dedicated programs to help women regain confidence and skills.</Typography></li>
                  <li><Typography><strong>Flexible Opportunities:</strong> Tailored job placements that accommodate the needs of postpartum women.</Typography></li>
                  <li><Typography><strong>Community and Support:</strong> A network of support to ensure a successful return to work.</Typography></li>
                </ul>
              </Paper>
              <Paper elevation={3} className="product">
                <Typography variant="h5"><b>Wellness Plated</b></Typography>
                <Typography>Holistic Nutrition and Health Advice</Typography>
                <ul>
                  <li><Typography><strong>Personalized Nutrition Plans:</strong> Tailored to your unique health goals and needs.</Typography></li>
                  <li><Typography><strong>Expert Guidance:</strong> Access to nutritionists and wellness experts for comprehensive advice.</Typography></li>
                  <li><Typography><strong>Comprehensive Resources:</strong> A wealth of information to support your health journey.</Typography></li>
                </ul>
              </Paper>
            </Stack>
          </section>
          <section id="model" className="section model-section">
            <Typography variant="h4" component="h2" gutterBottom>
              Our Model
            </Typography>
            <ul>
              <li><Typography><strong>Innovate:</strong> We believe in pushing boundaries to develop groundbreaking healthcare solutions. Our team constantly explores new technologies and ideas to stay ahead of the curve and deliver cutting-edge products.</Typography></li>
              <li><Typography><strong>Personalize:</strong> Healthcare is not one-size-fits-all. We tailor our offerings to meet the diverse needs of our clients, ensuring each product and service is as unique as the people we serve.</Typography></li>
              <li><Typography><strong>Empower:</strong> We empower individuals to take control of their health by providing tools and support that make healthcare accessible and effective. Our goal is to inspire healthier living and help you thrive in every aspect of life.</Typography></li>
            </ul>
          </section>
          <section id="team" className="section team-section">
            <Typography variant="h4" component="h2" gutterBottom>
              Our Team
            </Typography>
            <Typography>Coming soon... Meet the passionate individuals driving innovation at D&D Healthcare.</Typography>
          </section>
        </Container>
      </main>
      <footer className="footer">
        <Typography align="center">&copy; 2024 D&D Healthcare. All rights reserved.</Typography>
        <Box className="socialLinks" textAlign="center">
          <Button href="https://www.linkedin.com/in/dnd.healthcare/">LinkedIn</Button>
          <Button href="https://x.com/ddhealthcareinc">Twitter</Button>
          <Button href="https://www.instagram.com/dnd.healthcare/">Instagram</Button>
        </Box>
      </footer>
    </div>
  );
};

export default App;